<template>
  <div class="wrapper-loader">
    <span class="loader"></span>
  </div>
</template>
<!-- <template>
  <div class="wrapper-loader">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template> -->
